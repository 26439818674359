import React, { Component, Suspense, lazy } from 'react'
import Loading from './views/Loading/Loading'
import { Switch, Route, BrowserRouter } from "react-router-dom";
import App from './views/App/App'

export default class Main extends Component {
  render() {
    const Admin = lazy(() => import('./views/Admin/Admin'));
    return (
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/admin" component={Admin} />
            <Route component={App} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    )
  }
}
