import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo.png'
import Contacts from './Contacts'

export default function Header({ mustHideHeader}) {
  return (
    <header className="App-Header" style={mustHideHeader ? {"display": "none"} : {}}>
      <img src={logo} className="App-Header-Logo" alt="Yog'Âme Logo"></img>
      <div className='App-Header-Title'>
        <Link to="/accueil">Yog'Âme</Link>
      </div>
      <Contacts />
    </header>
  )
}
