import React, { Component } from 'react'
import { Switch, Route, Redirect, Link, withRouter } from "react-router-dom"
import './App.css';

import Header from '../../components/Header';
import Contacts from '../../components/Contacts'
import Loading from "../Loading/Loading"
import Parser from "html-react-parser"

import { Helmet } from 'react-helmet';

import logo from '../../assets/logo.png'
import { ReactComponent as Yoga } from "../../assets/undraw_mindfulness_scgo.svg"
import { ReactComponent as Yoga2 } from "../../assets/yoga.svg"
import { ReactComponent as Massage } from "../../assets/massage.svg"
import { ReactComponent as Complements } from "../../assets/complements.svg"
import { ReactComponent as Cook } from "../../assets/cook.svg"
import { ReactComponent as MakeUp } from "../../assets/makeup.svg"
import axios from "axios"

import Amplify, { Storage } from 'aws-amplify';
import awsconfig from '../../aws-exports';
Amplify.configure(awsconfig);

const components = {
  Yoga,
  Yoga2,
  Massage,
  Complements,
  Cook,
  MakeUp
}

class App extends Component {

  state = {
    content: null,
    offset: 0
  }

  componentDidMount() {
    this.initContent()
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    this.setState({ offset: window.pageYOffset });
  }
  initContent = async () => {
    let url = await Storage.get('config.json')
    const content = (await axios.get(url)).data
    this.setState({ content })
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, c - c / 8);
        }
      };
      scrollToTop();
    }
  }
  renderSection = (c) => {
    const dimension = 300 + this.state.offset * 0.5
    // const backgroundPositionY = this.state.offset * 0.5
    const Logo = components[c.contentLogo]
    return (
      <section className="App-Section">
        <div className={`${c.contentBG} App-Section-Background`}></div>
        <Helmet>
          <title>Yog'Âme - {c.contentTitle}</title>
          <meta name="description" content={c.contentDescription} />
        </Helmet>
        <div id={`${c.id}-element`} className="Description-Container">
          <div className="Item-Content">
            <div className="Logo-Title-Container">
              <div style={{ animation: 'none' }} className="Title">
                <div>{c.navigationTitle}</div>
              </div>
              <Logo className="App-SVG" style={{
                width: `${dimension}px`,
                height: `${dimension}px`
              }} />
            </div>
            <div className="Description-Text">
              {Parser(c.contentBody)}
            </div>
          </div>
        </div>
      </section>
    )
  }
  renderHome = () => {
    return (
      <section className='App-Section'>
        <div className={"App-Section-Home App-Section-Background"}></div>
        <div id="App-First-Screen">
          <div className='App-First-Screen-Contents'>
            <div className='App-Logo-Home-Ctn'>
              <img src={logo} className="App-Logo-Home" alt="Accueil" />
            </div>
            <div className="App-Main-Title-Container">
              <div className="App-Main-Title">Yog'Âme</div>
              <div className="App-Main-Subtitle">Yogas & Massages</div>
              <div className="App-Main-Legend">
                <h3>Namaste,</h3>
                <p>Je suis Amélie et ma mission : prendre soin de vous !</p>
                <p>Pour vous accompagner dans le bien-être, voici mon offre de services complémentaires :</p>
                <p>Yogas, Massages, Compléments alimentaires naturels à base de micro-algues, Mise en Beauté, Atelier Pâtisserie/Cuisine.</p>
                <p>Heureuse de vous croiser sur le chemin du bien-être !</p>
              </div>
              <div className="App-Button-Circle" onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>
                &darr;
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  render() {
    const mustHideHeader = this.state.offset < 120
    const mustHideMobileHeader = this.state.offset < 40
    return (
      this.state.content
        ? <div id="Container" className="App-Main-Container">
          <Header mustHideHeader={mustHideHeader}/>
          <header className="Mobile-App-Contacts" style={mustHideMobileHeader ? {display: 'none'} : {}}>
            <Contacts />
          </header>
          <div className="BlurLeft"></div>
          <div className="BlurRight"></div>
          <main className="App-Contents-Container">
            <Switch>
              <Route exact path="/">
                <Redirect to="/accueil" />
              </Route>
              <Route exact path="/accueil">
                {this.renderHome()}
              </Route>
              {this.state.content.contents.map(c => {
                return (
                  <Route key={`route-${c.id}`} exact path={`/${c.contentTitle.split(' ').join('-').toLowerCase()}`}>
                    {this.renderSection(c)}
                  </Route>
                )
              })}
            </Switch>
            <nav className="App-Tiles-Container">
              {this.state.content.contents.filter(c => {
                return `/${c.contentTitle.split(' ').join('-').toLowerCase()}` !== this.props.location.pathname
              }).map(c => {
                return (
                  <Link
                    key={`link-${c.id}`}
                    className="Tile"
                    to={`/${c.contentTitle.split(' ').join('-').toLowerCase()}`}
                  >
                    <div className="App-Tile-Darken"></div>
                    <div className={`App-BG-Blur App-Fit-Blur ${c.contentBG}`}></div>
                    <div style={c.contentTitleStyle} className="Title Tile-Title"><div>{c.contentTitle}</div></div>
                  </Link>
                )
              })}
            </nav>
          </main>
        </div>
        : <Loading />
    )
  }
}
export default withRouter(App)