import React from 'react'
import { ReactComponent as Facebook } from "../assets/fb.svg"
import { ReactComponent as Mail } from "../assets/mail.svg"
import { ReactComponent as Instagram } from "../assets/insta.svg"
import { ReactComponent as Phone } from "../assets/phone.svg"

export default function Contacts() {
  return (
    <div className="Socials-Container">
      <div className="Socials-Item-Container">
        <a className="Social-Link" href="https://www.facebook.com/YogAmebyAmelieGiraud" rel="noopener noreferrer" target="_blank"><Facebook className="Social-Item" /></a>
        <a className="Social-Link" href="https://www.instagram.com/YogAmebyAmelieGiraud" rel="noopener noreferrer" target="_blank"><Instagram className="Social-Item" /></a>
        <a className="Social-Link" href="mailto:Giraud.amelie44@gmail.com"><Mail className="Social-Item" /></a>
        <a className="Social-Link" href="tel:+33634025285"><Phone className="Social-Item" /></a>
      </div>
    </div>
  )
}
