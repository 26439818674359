import React from 'react'
import { Loader } from './Loader'
import './Loading.css'

const Loading = () => {
  return (
    <div className="Loading-Container">
      <Loader />
    </div>
  )
}
export default Loading
