import React from 'react'
import logo from "../../assets/logo.png"
import "./Loader.css"

export const Loader = () => {
  return (
    <div className="Loader-Container">
      <img src={logo} className="Loader-Logo" alt="YogAme" />
    </div>
  )
}
