/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:c7b51581-a973-4de5-ac88-de9c85710a67",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_g0ttQE9NH",
    "aws_user_pools_web_client_id": "6m4e3tto6nvgc0790j4jdcatmb",
    "oauth": {},
    "aws_user_files_s3_bucket": "yogame962ecf5d3ca64cf1a2768be4f382719a145201-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
